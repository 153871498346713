import http from "./http"

const BASE_URL = "/api/duty/student";


//用户信息模块

const BASE_PREFIX = "qljt_duty_"
export const doLogin = (username, password) => {
    return http.requestPost("/api/login?username=" + username + "&password=" + password, null, {u: BASE_PREFIX + username})
}

export const getLoginUser = () => {
    return http.requestGet("/api/user/get")
}

export const getDutyStudentInfo = (id) => {
    return http.requestGet(BASE_URL+"/get/"+id)
}

export const listDutyStudentInfo = () => {
    return http.requestGet(BASE_URL + "/list")
}

export const saveOrUpdateDutyStudentInfo = (data) => {
    return http.requestPost(BASE_URL + "/sou", data)
}

export const delDutyStudentInfo = (data) => {
    return http.requestDelete(BASE_URL + "/del" ,data)
}
