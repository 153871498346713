import Vue from 'vue'
import App from './App.vue'
import {router} from "./router"


import {store} from "@/store";

import iView from 'iview';
import 'iview/dist/styles/iview.css';
import xjyCaptchaMaster from "xjy-captcha-master"

Vue.component("captcha", xjyCaptchaMaster.captcha)
Vue.use(iView);

Vue.config.productionTip = false

console.error = function () {
    return
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
