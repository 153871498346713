import {getLoginUser} from "@/api/request";
import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        userInfo: {},
        isLogin: false
    },
    mutations: {
        getLoginUserInfo: function (state) {
            getLoginUser().then(res => {
                console.log(res)
                if (res.code === 20000&&res.data!=null) {
                    state.isLogin = true
                    state.userInfo = res.data
                } else {
                    state.isLogin = false
                    state.userInfo = {}
                }
            }).catch(err => {
                console.log(err)
                state.isLogin = false
                state.userInfo = {}
            })
        },
    }
})