var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"bturn":"","columns":_vm.columns,"data":_vm.dutyStudentInfoList},scopedSlots:_vm._u([{key:"sduId",fn:function(ref){
var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(row.sduId))])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(row.sduInfo.sduName))])]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Button',{attrs:{"type":"error","size":"small"},on:{"click":function($event){return _vm.remove(index)}}},[_vm._v("删除")])]}}])}),(_vm.show)?_c('div',{staticStyle:{"padding":"5px","margin-top":"10px","bturn-radius":"5px","box-shadow":"rgb(213 213 213) 0px 0px 5px 1px"}},[_c('div',{staticStyle:{"display":"flex","align-content":"center","justify-content":"center"}},[_c('span',{staticStyle:{"height":"30px","line-height":"40px"}},[_vm._v(" 学号： ")]),_c('Input',{staticStyle:{"width":"250px","height":"30px"},attrs:{"placeholder":"请输入学号"},model:{value:(_vm.sduId),callback:function ($$v) {_vm.sduId=$$v},expression:"sduId"}})],1),_c('div',{staticStyle:{"display":"flex","align-content":"center","justify-content":"center"}},[_c('span',{staticStyle:{"height":"30px","line-height":"40px"}},[_vm._v(" 序号： ")]),_c('Input',{staticStyle:{"width":"250px","height":"30px"},attrs:{"placeholder":"请输入序号(数字)","type":"number"},model:{value:(_vm.turn),callback:function ($$v) {_vm.turn=$$v},expression:"turn"}})],1),_c('Button',{staticStyle:{"margin":"10px"},attrs:{"disabled":!_vm.sduId,"type":"primary"},on:{"click":_vm.doAddDutyStudent}},[_vm._v(" 添加 + ")])],1):_vm._e(),_c('Button',{staticStyle:{"margin":"10px"},attrs:{"type":"primary"},on:{"click":_vm.addDutyStudent}},[_vm._v("添加新成员 +")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }