<template>
  <div style="margin: 10px;display: flex;justify-content: center; flex-direction: column">

    <div>
      <div class="table-box">
        <table style="margin: auto">
          <tbody>
          <tr>
            <td v-for="(item,pos) in ' 一二三四五六日'" :key="pos">
              <span class="man week" style="height:50px">{{ item == ' ' ? '' : '周' }}{{ item }}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <table id="courseInfo" v-if="ok" style="margin: auto">
          <tbody>
          <tr v-for="(value,pos) in table" :key="pos">
            <td>
              <span class="man week">第{{ '一二三四五'[pos] }}大节</span>
            </td>
            <td v-for="(item,index) in value" :key="Index" class="">
                                            <span v-if="courseInfo[index][pos]===1" class="man color-red"
                                                  @click="changeInfo(index,pos)">有课</span>
              <span v-else class="man color-white"
                    @click="changeInfo(index,pos)">无课</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <i-table size="small" ref="table" style="display: none"></i-table>


    <div style="margin:10px">
      <i-button @click="save" type="success" size="large">保存</i-button>
    </div>

  </div>
</template>

<script>


import {getDutyStudentInfo, saveOrUpdateDutyStudentInfo} from "@/api/request";


export default {
  name: "duty",
  data() {
    return {
      duration: 0,
      interval: null,
      dutyStudentInfo: [],
      courseInfo: [],
      ok: true,
      table: [[0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0]],
      dutyButtonTip: "排班",
      dutyTimes: 0,
    }
  },
  mounted() {
    getDutyStudentInfo(this.$store.state.userInfo.sduId).then(res => {
      console.log(res)
      this.dutyStudentInfo = res.data;
      this.courseInfo = JSON.parse(res.data.course)
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    onClick(e, pos, Index) {
      let that = this
      that.currentList = []

      this.pos = pos
      this.Index = Index
      this.courseInfo.forEach((item, index) => {
        if (item[pos][Index] !== 1 && that.excludedList.indexOf(that.dutyManList[index]) > -1) {
          that.currentList.push({
            index: index,
            name: that.dutyManList[index]
          })
        }
      })

    },
    changeInfo(pos, index) {
      this.courseInfo[pos][index] = this.courseInfo[pos][index] ? 0 : 1
      this.ok = false
      this.ok = true
    },
    save() {
      var that = this
      if (that.duration === 0) {
        var data = that.dutyStudentInfo
        data.course = JSON.stringify(this.courseInfo)
        saveOrUpdateDutyStudentInfo(data).then(res => {
          console.log(res)
          if(res.code===20000){
            that.$Message.success("保存成功！")
          }else {
            that.$Message.success(res.message)
          }
          that.duration = 10
          that.interval = setInterval(() => {
            if (that.duration > -1) {
              that.duration--
            } else {
              clearInterval(that.interval)
            }
          }, 1000)
        }).catch(err => {
          console.log(err)
        })
      } else {
        that.$Message.warning("请" + that.duration + "s后再试！")
      }
    }
  }
}
</script>

<style scoped>

.table-box {

  width: fit-content;
  box-shadow: rgba(108, 99, 243, 0.22) 0px 0px 13px 0px;
  /*padding: 10px;*/
  margin: 10px auto;
  border-radius: 10px;
}

.man {
  box-shadow: 0 0 2px 0px;
  color: #4685ba;
  cursor: pointer;
  width: 35px;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  height: 75px;
  margin: 0 3px;
  line-height: 35px;
  border-radius: 8px;
  user-select: none;
}

.man:active {
  opacity: 0.8;
  transform: scale(0.95);
}

.line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.color-white {
  color: #946c6c;
}

.color-red {
  color: red;
  font-weight: bold;
}

.week {
  color: white;
  background: #363891c4;
  box-shadow: 0 0 19px 0px #d7d8ff;
  font-size: 15px;
}
</style>
