<template>
  <div id="app">
    <div class="header">
      <a href="/">
        <Avatar icon="ios-home" size="large" style="color: #ffffff;background-color:rgb(43 14 106)"/>
      </a>
      <Swiper :list="['续加仪的博客','写了一个排班系统']" height="40"></Swiper>
      <a href="https://www.hyz.cool/" title="续加仪的博客">
        <Avatar src="https://hyz.cool/public/R/img/link.jpg" size="large"/>
      </a>
    </div>

    <div class="box">
      <div v-if="!$store.state.isLogin"
           style="padding-top:100px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <captcha v-if="showCaptcha" style="z-index: 10" @close="showCaptcha=false" @fail="fail" @success="success"/>
        <Input prefix="ios-contact" v-model="password" placeholder="输入姓名" style="width: 80%;margin: 15px 5px"
               size="large"/>
        <Input prefix="ios-lock" v-model="username" placeholder="输入学号" style="width: 80%;margin: 15px 5px"
               size="large" type="password"/>
        <Button :disabled="!username||!password" type="primary" @click="doLogin" style=" margin: 15px 5px">登录</Button>
      </div>
      <div v-else>
        <div>{{ $store.state.userInfo.sduInfo.sduName }}-{{ $store.state.userInfo.sduId }}</div>
        <router-view></router-view>
      </div>
    </div>
    <div class="footer-box">
      <div v-for="(item,index) in linkList" :key="index">
        <a :href="item.url" target="_blank">{{ item.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "@/components/Swiper";
import {doLogin} from "@/api/request";

export default {
  name: 'App',
  components: {
    Swiper
  },
  data() {
    return {
      linkList: [
        {
          name: '续加仪的博客',
          url: 'https://www.hyz.cool/'
        }
      ],
      username: "",
      password: "",
      showCaptcha: false
    }
  },
  mounted() {
    this.$store.commit("getLoginUserInfo")
  },
  methods: {
    doLogin() {
      if (!this.username) {
        this.$Message.error("学号不能为空");
        return;
      }
      if (!this.password) {
        this.$Message.error("姓名不能为空");
        return;
      }
      this.showCaptcha = true;
    },
    success() {
      this.$Message.success("验证成功！")
      doLogin(this.username, this.password).then(res => {
        if (res.code === 20000) {
          this.$Message.success("登录成功！")
          this.$store.commit("getLoginUserInfo")
          return
        }
        this.$Message.error(res.message);
      }).catch(err => {
        this.$Message.error(err.message);
      })
    },
    fail() {
      this.$Message.error("认证失败！")
    }
  }
}
</script>

<style>
#app {
  font-family: fantasy;
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  /*color:white;*/
  font-size: large;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box {
  min-height: 600px;
  width: 400px;
}

.box > :first-child {
  /*width: 385px;*/
  box-shadow: 0 0 16px 3px #f4f4f4;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
}

.header {
  display: flex;
  height: 60px;
  line-height: 60px;
  justify-content: space-between;
  align-items: center;
  width: 385px;
  margin: 10px 0 0;
  box-shadow: 0 0 16px 3px #f4f4f4;
  padding: 20px;
  border-radius: 10px;
}

.footer-box {
  display: flex;
  height: 60px;
  line-height: 60px;
  justify-content: center;
  align-items: center;
  width: 385px;
  margin: 5px 0 0;
  box-shadow: 0 0 16px 3px #f4f4f4;
  padding: 20px;
  border-radius: 10px;
}

a {
  color: rgba(0, 0, 0, 0.55);
}

a:hover {
  color: rgba(0, 0, 0, 0.55);
  opacity: 0.6;
}
</style>
