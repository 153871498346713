<template>
  <div>
    <Table bturn :columns="columns" :data="dutyStudentInfoList">
      <template #sduId="{ row }">
        <strong>{{ row.sduId }}</strong>
      </template>
      <template #name="{ row }">
        <strong>{{ row.sduInfo.sduName }}</strong>
      </template>
      <template #action="{ row, index }">
        <!--        <Button type="primary" size="small" style="margin-right: 5px" @click="edit(index)">编辑</Button>-->
        <Button type="error" size="small" @click="remove(index)">删除</Button>
      </template>
    </Table>

    <div v-if="show"
         style="padding:5px;margin-top:10px;bturn-radius: 5px;box-shadow:rgb(213 213 213) 0px 0px 5px 1px">
      <div style="display: flex;align-content: center;justify-content: center">
       <span style="height: 30px;line-height: 40px;">
          学号：
        </span>
        <Input v-model="sduId" placeholder="请输入学号" style="width:250px;height: 30px"/>
      </div>
      <div style="display: flex;align-content: center;justify-content: center">
       <span style="height: 30px;line-height: 40px;">
          序号：
        </span>
        <Input v-model="turn" placeholder="请输入序号(数字)" type="number" style="width:250px;height: 30px"/>
      </div>
      <Button :disabled="!sduId" style="margin:10px" type="primary" @click="doAddDutyStudent">
        添加 +
      </Button>
    </div>
    <Button style="margin:10px" type="primary" @click="addDutyStudent">添加新成员 +</Button>
  </div>
</template>

<script>
import {
  delDutyStudentInfo,
  listDutyStudentInfo,
  saveOrUpdateDutyStudentInfo,
} from "@/api/request";

export default {
  layout: "confirm",
  name: "assistant",
  data() {
    return {

      dutyStudentInfoList: [],
      columns: [
        {
          title: '序号',
          key: 'index',
          align: 'center',
          width: 70
        },
        {
          title: '学号',
          slot: 'sduId',
          width: 130,
          align: 'center'
        },
        {
          title: '姓名',
          slot: 'name',
          width: 90,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'action',
          width: 80,
          align: 'center'
        }
      ],
      show: false,
      sduId: 0,
      turn: ""
    }
  },
  mounted() {

    this.listDutyStudentInfo()
  },
  methods: {
    listDutyStudentInfo() {
      listDutyStudentInfo().then(res => {
        this.dutyStudentInfoList = res.data.map((item, index) => {
          return {
            ...item, index: index + 1
          }
        })
        console.log(this.dutyStudentInfoList)
      })
    },
    addDutyStudent() {
      this.sduId = ""
      this.turn = ""
      this.type = 0
      this.show = true;
    },
    remove(index) {
      delDutyStudentInfo({idList: [this.dutyStudentInfoList[index].id], status: 0}).then(res => {
        console.log(res)
        if(res.code===20000){
          this.$Message.success("删除成功！")
          this.listDutyStudentInfo()
        }
      })
    },
    doAddDutyStudent() {
      saveOrUpdateDutyStudentInfo({
        sduId: this.sduId,
        turn: this.turn,
        course: "[[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,1,1,1],[1,1,1,1,1],[1,1,1,1,1]]"
      }).then(res => {
        if (res.code === 20000) {
          this.show = false;
          this.$Message.success("保存成功！")
          this.listDutyStudentInfo()
          return
        } else if (res.code === 50000) {
          this.$Message.error("该学号不存在！")
          return
        }
        this.$Message.error(res.message)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
div {
  margin: 5px 0;
}

.box > :first-child {
  width: 100%;
}
</style>