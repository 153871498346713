<template>
    <div>
        <div class="action-box">
            <router-link to="/duty">
                <i-button type="success" size="large" class="btn">录入课表</i-button>
            </router-link>
            <router-link to="/mp">
                <i-button type="primary" size="large" class="btn">管理排班</i-button>
            </router-link>
            <router-link to="/assistant">
                <i-button type="warning" size="large" class="btn">管理助理</i-button>
            </router-link>

        </div>

    </div>
</template>

<script>
    export default {
        name: "index",
        methods: {
        }
    }
</script>


<style scoped>

    .action-box {
        margin: 20px 10px;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .btn {
        margin: 20px;
        font-size: 23px;
        padding: 10px 20px;
    }


</style>
