<template>
  <div style="margin: 10px;display: flex;justify-content: center;flex-direction: column">

    <!--            <div>-->
    <div>
      <div class="table-box">
        <table style="margin: auto">
          <tbody>
          <tr>
            <td v-for="(item,pos) in ' 一二三四五六日'" :key="pos">
              <span class="man week" style="height:50px">{{ item == ' ' ? '' : '周' }}{{ item }}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <table id="courseInfo" v-if="ok" style="margin: auto">
          <tbody>
          <tr v-for="(value,pos) in table" :key="pos">
            <td>
              <span class="man week">第{{ '一二三四五'[pos] }}大节</span>
            </td>
            <td v-for="(Item,Index) in value" :key="Index" class="">
                                         <span class="man " @click="e=>onClick(e,Index,pos)"
                                         >
                                                  {{ dutyInfo[Index][pos] ? dutyInfo[Index][pos] : '无人' }}
                                         </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <i-table size="small" ref="table" style="display: none"></i-table>
    <div style="margin: 20px 0">
      <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
        <Checkbox
            :indeterminate="indeterminate"
            :value="checkAll"
            @click.prevent.native="handleCheckAll">选择参与排班人员
        </Checkbox>
      </div>
      <Checkbox-group v-model="excludedList" @on-change="checkAllGroupChange" class="label-list">
        <Checkbox v-for="(item,index) in dutyManList" :label="item" :key="index"/>
      </Checkbox-group>
    </div>
    <div v-if="ok">
      <span>周{{ '一二三四五六日'[pos] }}</span>
      <span>第{{ '一二三四五'[Index] }}大节</span>
      <Select size="large" style="width:100px" @on-change="onChangePos" v-model="selectValue">
        <Option :value="Index1" v-for="(item,Index1) in currentList" :key="Index">{{ item.name }}</Option>
      </Select>
      <span>值班</span>
    </div>
    <div style="    margin: 20px 40px;    display: flex;    justify-content: space-evenly;">
      <i-button size="large" @click="duty" type="info" style="margin: 0 5px">{{ dutyButtonTip }}</i-button>
      <i-button size="large" @click="exportCsv" type="primary">导出</i-button>
    </div>

    <div v-if="ok" class="label-list" style="margin: 20px 0">
                <span v-for="(item,pos) in dutyManList" :key="pos"
                      style="margin: 0 5px">{{ item }}:{{ manCountInfo[pos] }}</span>
    </div>
  </div>
</template>

<script>


import {listDutyStudentInfo} from "../api/request";
import {CONFIRM_CODE} from "../Constance";

export default {
  name: "admin",
  data() {
    return {
      count: 0,
      interval: null,
      index: 0,
      dbInfo: [],
      dutyManList: [],
      courseInfo: [],
      dutyInfo: [],
      ok: true,
      currentList: [],
      pos: 0,
      Index: 0,
      manCountInfo: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      table: [[0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0]],
      checkAll: true,
      indeterminate: false,
      excludedList: [],
      dutyButtonTip: "排班",
      dutyTimes: 0,
      selectValue: 0
    }
  },
  mounted() {
    if (location.href.indexOf('localhost') >= 0) {
      this.confirm_ok = true
    }
    if (this.courseInfo.length == 0) {
      var arr = new Array(7)
      for (var i = 0; i < 7; i++) {
        arr[i] = ['', '', '', '', '']
      }
      this.dutyInfo = arr
      listDutyStudentInfo().then(res => {
        console.log(res)
        if (res.code === 20000) {
          this.$Message.success("获取列表成功！")
          this.dutyManList = res.data.map(item => item.sduInfo.sduName)
          this.excludedList = this.dutyManList
          this.courseInfo = res.data.map(item => JSON.parse(item.course))
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  methods: {
    onChange(e) {
      this.index = e.target.value
      if (this.courseInfo[this.index] == null) {
        var arr = new Array(7)
        for (var i = 0; i < 7; i++) {
          arr[i] = new Array(5)
        }
        this.courseInfo[this.index] = arr
      }
    },
    onClick(e, pos, Index) {
      let that = this
      that.currentList = []
      this.selectValue = 0
      this.pos = pos
      this.Index = Index
      this.courseInfo.forEach((item, index) => {
        if (item[pos][Index] !== 1 && that.excludedList.indexOf(that.dutyManList[index]) > -1) {
          that.currentList.push({
            index: index,
            name: that.dutyManList[index]
          })
        }
      })

    },
    handleCheckAll() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;

      if (this.checkAll) {
        this.excludedList = this.dutyManList
      } else {
        this.excludedList = [];
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.dutyManList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    onChangePos(value) {
      this.dutyInfo[this.pos][this.Index] = this.currentList[value].name
      this.dealMenCount()
      this.ok = false
      this.ok = true
    },
    changeInfo(pos, index) {
      console.log(this.courseInfo[this.index][pos][index])
      this.courseInfo[this.index][pos][index] = this.courseInfo[this.index][pos][index] ? 0 : 1
      this.ok = false
      this.ok = true
    },
    duty() {
      var from_pos = this.dutyTimes % 7
      console.log(from_pos)
      let that = this
      that.currentList = []
      that.manCountInfo = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      for (var i = 0; i < 7; i++) {
        var arr = [[], [], [], [], []]
        that.currentList.push(arr)
      }

      let currentList = []
      for (var pos = 0; pos < 7; pos++) {
        for (var Index = 0; Index < 5; Index++) {
          this.courseInfo.forEach((item, index) => {
            if (item[pos][Index] !== 1 && that.excludedList.indexOf(that.dutyManList[index]) > -1) {
              currentList.push({
                index: index,
                name: that.dutyManList[index]
              })
            }
          })
          that.currentList[pos][Index] = currentList.sort(function () {
            return 0.5 - Math.random()
          }).sort(function () {
            return 0.5 - Math.random()
          })
          currentList = []
        }
      }

      // console.log(this.currentList)
      var item1, item2, temp, obj, man1, man2, man
      for (pos = from_pos; pos < 7; pos++) {
        for (Index = 0; Index < 5; Index = Index + 2) {
          if (Index < 3) {
            item1 = that.currentList[pos][Index]
            item2 = that.currentList[pos][Index + 1]
            if (item1.length < item2.length) {

              temp = item1
              item1 = item2
              item2 = temp

            }

            obj = null
            item1.forEach(item1_1 => {
              if (!obj) {
                item2.forEach(item2_2 => {
                  if ((item1_1.name === item2_2.name) && that.getCount(item1_1.name) < (35 / this.dutyManList.length - 1)) {
                    obj = item1_1
                    return
                  }
                })
              } else {
                return
              }
            })

            if (obj) {
              that.dutyInfo[pos][Index] = obj.name
              that.dutyInfo[pos][Index + 1] = obj.name
              that.dealManCount(obj.name)
              that.dealManCount(obj.name)
            } else {
              if (that.currentList[pos][Index].length !== 0) {
                man1 = that.getMan(pos, Index)
                that.dealManCount(man1)
              } else {
                man1 = "无人"
              }
              that.dutyInfo[pos][Index] = man1
              if (that.currentList[pos][Index + 1].length !== 0) {
                man2 = that.getMan(pos, Index)
                that.dealManCount(man2)
              } else {
                man2 = "无人"
              }
              that.dutyInfo[pos][Index + 1] = man2
            }
          } else {
            man = that.getMan(pos, Index)
            that.dutyInfo[pos][Index] = man
            that.dealManCount(man)
          }
        }

      }
      for (pos = 0; pos < from_pos; pos++) {
        for (Index = 0; Index < 5; Index = Index + 2) {
          if (Index < 3) {
            item1 = that.currentList[pos][Index]
            item2 = that.currentList[pos][Index + 1]
            if (item1.length < item2.length) {
              temp = item1
              item1 = item2
              item2 = temp
            }

            obj = null
            item1.forEach(item1_1 => {
              if (!obj) {
                item2.forEach(item2_2 => {
                  if ((item1_1.name === item2_2.name) && that.getCount(item1_1.name) < (35 / this.dutyManList.length - 1)) {
                    obj = item1_1
                    return
                  }
                })
              } else {
                return
              }
            })

            if (obj) {
              that.dutyInfo[pos][Index] = obj.name
              that.dutyInfo[pos][Index + 1] = obj.name
              that.dealManCount(obj.name)
              that.dealManCount(obj.name)
            } else {
              if (that.currentList[pos][Index].length !== 0) {
                man1 = that.getMan(pos, Index)
                that.dealManCount(man1)
              } else {
                man1 = "无人"
              }
              that.dutyInfo[pos][Index] = man1
              if (that.currentList[pos][Index + 1].length !== 0) {
                man2 = that.getMan(pos, Index)
                that.dealManCount(man2)
              } else {
                man2 = "无人"
              }
              that.dutyInfo[pos][Index + 1] = man2
            }
          } else {
            man = that.getMan(pos, Index)
            that.dutyInfo[pos][Index] = man
            that.dealManCount(man)
          }
        }

      }
      // console.log(this.dutyInfo)
      // if (!that.isOk()) {
      //     that.duty(++from_pos)
      // }else {
      that.dutyTimes += 1;
      that.dutyButtonTip = "再排一次"
      that.$Message.success("排班完成！")
      that.ok = false
      that.ok = true
      // }
    },
    exportCsv() {
      console.log("export")

      let dutyInfo = this.dutyInfo
      let res = [[], [], [], [], []].map(() => {
        return ['', '', '', '', '', '', '']
      })
      dutyInfo.forEach((value, pos) => {
        value.forEach((item, index) => {
          res[index][pos] = item
        })
      })
      let column = ['一', '二', '三', '四', '五', '六', '日'].map(item => {
        return '周' + item
      })
      let list = ["姓名"].concat(this.dutyManList)
      let countList = ["时长"]

      var that = this
      list.forEach((item, index) => {
        if (index > 0) {
          countList.push(that.manCountInfo[that.dutyManList.indexOf(item)])
        }
      })
      this.$refs.table.exportCsv({
        filename: '值班信息表',
        columns: column,
        data: [column].concat(res).concat([[], list, countList])
      });
    },
    dealManCount(man) {
      var index = this.dutyManList.indexOf(man)
      if (index > -1) {
        this.manCountInfo[index]++
      }
    },
    dealMenCount() {
      this.manCountInfo = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.dutyInfo.forEach(value => {
        value.forEach(item => {
          var index = this.dutyManList.indexOf(item)
          if (index > -1)
            this.manCountInfo[index]++
        })
      })
    },
    getMan(pos, Index) {
      if (this.currentList[pos][Index].length === 0) {
        return "无人"
      } else if (this.currentList[pos][Index].length === 1) {
        return this.currentList[pos][Index][0].name
      }
      var sort = this.sort(pos, Index)
      // console.log(sort)
      // var man =
      // if (this.getCount(man) > 4 ) {
      //     return this.getMan(pos, Index, ran + 1)
      // } else {
      // console.log(this.currentList[pos][Index][sort].name)
      return this.currentList[pos][Index][sort].name
      // }
    },
    sort(pos, Index) {
      var manCountInfo = []
      var that = this
      that.currentList[pos][Index].forEach((item, index) => {
        manCountInfo.push({index: index, count: this.manCountInfo[item.index]})
      })
      manCountInfo.sort(function (a, b) {
        return a.count - b.count
      })
      return manCountInfo[0].index
    },
    getCount(man) {
      var index = this.dutyManList.indexOf(man)
      if (index > -1) {
        return this.manCountInfo[index]
      } else {
        return 0
      }

    },
    isOk() {
      // var max = Math.max(...this.manCountInfo)
      var min = Math.min(...this.manCountInfo)
      if (min < 3) {
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>


.table-box {

  width: fit-content;
  box-shadow: rgba(108, 99, 243, 0.22) 0px 0px 13px 0px;
  padding: 10px;
  margin: 10px auto;
  border-radius: 10px;
}

.man {
  box-shadow: 0 0 2px 0px;
  color: #4685ba;
  cursor: pointer;
  width: 35px;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  height: 75px;
  margin: 0 3px;
  line-height: 35px;
  border-radius: 8px;
  user-select: none;
}

.man:active {
  opacity: 0.8;
  transform: scale(0.95);
}

.line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.color-white {
  color: #946c6c;
}

.color-red {
  color: red;
  font-weight: bold;
}

.week {
  color: white;
  background: #363891c4;
  box-shadow: 0 0 19px 0px #d7d8ff;
  font-size: 15px;
}

.label-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: left;
  font-size: small;
}
</style>
