import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import index from "./page/index"
import admin from "./page/admin"
import assistant from "./page/assistant"
import duty from "./page/duty"


const routes = [
    { path: '/', component: index },
    { path: '/mp', component: admin },
    { path: '/assistant', component: assistant },
    { path: '/duty', component: duty }
]
const createRouter = () =>
    new VueRouter({
        //浏览器
        mode: "history",
        //客户端
        // mode: "hash",
        routes
    });


// export function resetRouter() {
//     const newRouter = createRouter();
//     router.matcher = newRouter.matcher;
// }

 export const router= createRouter();
